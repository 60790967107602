import React, { useRef } from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Form from './sections/Form';

const Home = () => {
  const $ref = useRef();
  
  return (
    <Root>
      <Hero 
        $ref={$ref}
      />
      {/* <Form 
        $ref={$ref}
      /> */}
    </Root>
  );
};

export default Home;

const Root = styled.div``;
