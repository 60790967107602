import React from 'react';
import styled from 'styled-components';

const instagram_icon = (props) => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={33}
      height={32}
      viewBox="0 0 33 32"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#7A9A01"
                d="M0 0L32 0 32.143 31.571 0 32z"
                transform="translate(-917.000000, -1727.000000) translate(0.000000, 1630.000000) translate(917.000000, 97.000000)"
                id='bg'
              />
              <path
                fill="#07272D"
                fillRule="nonzero"
                d="M19 16c0-1.652-1.348-3-3-3s-3 1.348-3 3 1.348 3 3 3 3-1.348 3-3zm1.617 0c0 2.554-2.063 4.617-4.617 4.617S11.383 18.554 11.383 16s2.063-4.617 4.617-4.617 4.617 2.063 4.617 4.617zm1.265-4.804c0 .597-.48 1.078-1.078 1.078-.597 0-1.078-.48-1.078-1.078 0-.598.48-1.078 1.078-1.078.598 0 1.078.48 1.078 1.078zM16 8.618c-1.312 0-4.125-.106-5.308.363-.41.164-.715.363-1.031.68-.317.316-.516.62-.68 1.03-.469 1.184-.363 3.997-.363 5.309 0 1.312-.106 4.125.363 5.308.164.41.363.715.68 1.031.316.317.62.516 1.03.68 1.184.469 3.997.363 5.309.363 1.312 0 4.125.106 5.308-.363.41-.164.715-.363 1.031-.68.317-.316.516-.62.68-1.03.469-1.184.363-3.997.363-5.309 0-1.312.106-4.125-.363-5.308-.164-.41-.363-.715-.68-1.031-.316-.317-.62-.516-1.03-.68-1.184-.469-3.997-.363-5.309-.363zM25 16c0 1.242.011 2.472-.06 3.715-.07 1.44-.398 2.718-1.452 3.773-1.055 1.054-2.332 1.382-3.773 1.453-1.243.07-2.473.058-3.715.058s-2.472.012-3.715-.058c-1.44-.07-2.718-.399-3.773-1.453-1.054-1.055-1.382-2.332-1.453-3.773-.07-1.243-.058-2.473-.058-3.715s-.012-2.472.058-3.715c.07-1.44.399-2.718 1.453-3.773 1.055-1.054 2.332-1.382 3.773-1.453 1.243-.07 2.473-.058 3.715-.058s2.472-.012 3.715.058c1.44.07 2.718.399 3.773 1.453 1.054 1.055 1.382 2.332 1.453 3.773.07 1.243.058 2.473.058 3.715z"
                transform="translate(-917.000000, -1727.000000) translate(0.000000, 1630.000000) translate(917.000000, 97.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </Root>
  )
}

export default instagram_icon;

const Root = styled.svg``;
