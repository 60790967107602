import React from 'react';
import styled from 'styled-components';

import Facebook from 'src/assets/images/facebook_icon';
import Twitter from 'src/assets/images/twitter_icon';
import Instagram from 'src/assets/images/instagram_icon';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Footer = () => {
  return (
    <Root>
      <Logo src={require('src/assets/images/logo-horizontal.svg')} alt='holland two' />
      <Top>
        <div>
          <h3>Contact</h3>
          <p>
            <span>T&nbsp;</span><a href='tel:604.951.8111'>604.951.8111</a>
          </p>
          <p>
            <span>E&nbsp;</span><a href='mailto:hollandtwo@townline.ca'>hollandtwo@townline.ca</a>
          </p>
        </div>
        <div>
          <a href='https://www.townline.ca/' target='_blank' rel='noopener noreferrer'>
            <img src={require('src/assets/images/logo_townline.svg')} alt='townline' />
          </a>
          <a href='https://www.townline.ca/' target='_blank' rel='noopener noreferrer'>
            <img src={require('src/assets/images/logo_townline_marketing.svg')} alt='townline' />
          </a>
        </div>
      </Top>
      <SocialMediaIcons>
        <a href='https://www.facebook.com/TownlineHomes/' target='_blank' rel='noopener noreferrer'><Facebook /></a>
        <a href='https://twitter.com/Townline_Homes/' target='_blank' rel='noopener noreferrer'><Twitter /></a>
        <a href='https://www.instagram.com/townlinehomes/' target='_blank' rel='noopener noreferrer'><Instagram /></a>
      </SocialMediaIcons>
      <Bottom>
        <div>
          <p>
            &copy; 2021 Townline. All Rights Reserved. &nbsp;
            <br/> 
            <a href='https://bamdigital.com/' target='_blank' rel='noopener noreferrer'>Website by Bam Digital</a>
          </p>
        </div>
        <p>Sales and Marketing by Townline Marketing Inc. The developer reserves the right to make changes, modifications or substitutions to the building design, specifications and floor plans should they be necessary. Final floor plans may be a mirror image of the floor plans shown. Balcony/patio sizes vary floor to floor. Interior square footage and room sizes are based on preliminary survey measurements. Sizes are approximate and actual square footage may vary from the final survey and architectural drawings. Renderings are artistic representation only. This is not an offering for sale. Any such offering can only be made by way of disclosure statement. E. &amp; O.E.</p>
      </Bottom>
    </Root>
  )
}

export default Footer;

const Root = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.color.winterGreen};
  ${vw('padding-top', 32, 40, 50)}
  ${vw('padding-right', 13, 91, 80)}
  ${vw('padding-bottom', 40, 40, 40)}
  ${vw('padding-left', 12, 90, 80)}
  a {
    &:hover {
      #bg {
        fill: #BCCC80;
      }
    }
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  ${vw('margin-bottom', 48, 56, 0)}
  @media ${media.desktop} {
    width: ${vwDesktop(333)};
    position: absolute;
    right: ${vwDesktop(80)};
    top: ${vwDesktop(56)};
  }
`;

const Top = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  ${vw('margin-bottom', 0, 0, 68)}
  div {
    &:nth-of-type(1) {
      ${vw('margin-bottom', 40, 48, 0)}
      h3 {
        ${vw('margin-bottom', 8)}
      }
      p {
        &:first-of-type {
          ${vw('margin-bottom', 4)}
        }
      }
    }
    &:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      ${vw('margin-bottom', 112, 136, 0)}
      ${vw('margin-right', 0, 0, 80)}
      h3 {
        ${vw('margin-bottom', 8, 8)}
      }
      a {
        display: flex;
        &:first-of-type {
          img {
            ${vw('width', 160, 200)}
            ${vw('margin-bottom', 24, 24, 32)}
          }
        }
        &:last-of-type {
          img {
            ${vw('width', 255, 402)}
          }
        }
      }
    }
    h3 {
      font-family: 'DINOT';
      font-weight: normal;
      text-transform: uppercase;
      ${vw('font-size', 14, 24)}
      ${vw('letter-spacing', 0.5, 0)}
    }
    p {
      font-family: 'Roboto';
      font-weight: 300;
      text-transform: uppercase;
      ${vw('font-size', 14, 16)}
      ${vw('letter-spacing', 0.5, 1)}
      ${vw('line-height', 22, 24)}
      span {
        font-weight: bold;
      }
      a {
        text-transform: lowercase;
        color: ${({ theme }) => theme.color.white};
        &:hover {
          color: ${({ theme }) => theme.color.lightGreen};
        }
      }
    }
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    div {
      &:nth-of-type(2) {
        align-items: flex-start;
      }
    }
    h3 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${vw('bottom', 302, 224)}
  a {
    ${vw('width', 24, 32, 32)}
    ${vw('height', 24, 32, 32)}
    svg {
      ${vw('width', 24, 32, 32)}
      ${vw('height', 24, 32, 32)}
    }
    &:nth-of-type(2) {
      ${vw('margin-right', 16, 12)}
      ${vw('margin-left', 16, 12)}
    }
  }
  @media ${media.desktop} {
    position: absolute;
    left: auto;
    right: ${vwDesktop(80)};
    bottom: ${vwDesktop(105)};
    transform: none;
  }
`;

const Bottom = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  ${vw('width', 280, 580, 1100)}
  img {
    ${vw('margin-bottom', 40, 40, 20)}
  }
  >div {
    p {
      ${vw('margin-bottom', 16)}
    }
  }
  p {
    font-family: 'Roboto';
    ${vw('font-size', 10, 12)}
    ${vw('letter-spacing', 0.5, 0.5)}
    a {
      color: ${({ theme }) => theme.color.white};
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.color.lightGreen};
      }
    }
  }
  @media ${media.tablet} {
    p {
      br {
        display: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
  @media ${media.desktop} {
    text-align: left;
    >div {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
`;