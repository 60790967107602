import React, { useState } from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Select = (props) => {
  const {
    name,
    label,
    options,
    setField,
    required,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Root isOpen={isOpen}>
      <label htmlFor={name}>{label}</label>
      <select 
        name={name}
        required={required}
        onChange={(e) => setField(e.target.value)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <option selected defaultValue="" disabled>Select</option>
        {options.map((option, index) => (
          <option 
            value={option.id}
            key={index}
          >
            {option.name}
          </option>
        ))}
      </select>
      <span />
    </Root>
  )
}

export default Select

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${vw('width', 295, 465, 423)}
  ${vw('margin-bottom', 40, 40, 48)}
  label {
    font-family: 'Roboto';
    line-height: normal;
    font-weight: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 14, 16, 16)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('margin-bottom', 8, 7, 7)}
  }
  select {
    outline: none;
    border: none;
    appearance: none;
    background-color: rgba(64, 96, 23, 0.1);
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 300;
    color: black;
    ${vw('font-size', 12, 14, 14)}
    ${vw('letter-spacing', 0.5, 0.7, 0.7)}
    ${vw('height', 33, 33, 33)}
    ${vw('padding-right', 11, 17, 16)}
    ${vw('padding-left', 11, 17, 16)}
  }
  span {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid black;
    position: absolute;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : ''};
    transition: all 0.4s ease;
    ${vw('right', 11, 17, 16)}
    ${vw('bottom', 14, 14, 14)};
  }
`;