import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import media from 'src/styles/media';
import vw, { vwTablet, vwDesktop } from 'src/styles/utils';

const Logo = (props) => {
  const { small } = props;
  const browser = useSelector(state => state.browser);
  const renderLogo = () => {
    switch(true) {
      case browser.is.mobile: 
        return <img src={require('src/assets/images/logo-horizontal.svg')} alt='holland two' />;
      case browser.is.tablet:
        return <img src={require('src/assets/images/logo-vertical.svg')} alt='holland two' />;
      case browser.is.desktop:
        return <img src={require('src/assets/images/logo-vertical.svg')} alt='holland two' />;
      default: 
        return;
    }
  }
  return (
    <Root
      mobile={require('src/assets/images/mobile_hero_pattern.jpg')}
      tablet={require('src/assets/images/tablet_hero_pattern.jpg')}
      desktop={require('src/assets/images/desktop_hero_pattern.jpg')}
      small={small}
    >
      {renderLogo()}
    </Root>
  )
}

export default Logo;

const Root = styled.div`
  position: relative;
  background-image: url(${props => props.mobile});
  background-size: cover;
  background-repeat: no-repeat;
  height: 30%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${vw('width', 258, 146, 100)}
  }
  @media ${media.tablet} {
    background-image: url(${props => props.tablet});
    background-size: cover;
    background-repeat: no-repeat;
    width: ${vwTablet(256)};
    height: 100%;
    ${props => props.small && css`
      width: ${vwTablet(192)}; 
    `}
  }
  @media ${media.desktop} {
    background-image: url(${props => props.desktop});
    background-size: cover;
    background-repeat: no-repeat;
    width: ${vwDesktop(432)};

    ${props => props.small && css`
      width: ${vwDesktop(360)};
      height: ${vwDesktop(480)};
    `}
  }
`;