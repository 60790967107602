import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Input = (props) => {
  const {
    name,
    label,
    pattern,
    setField,
    required,
  } = props;
  return (
    <Root>
      <label 
        htmlFor={name}
      >
        {label}
      </label>

      <input 
        type='text'
        name={name} 
        required={required}
        pattern={pattern}
        onChange={(e) => setField(e.target.value)}
      />
    </Root>
  )
}

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('width', 295, 465, 423)}
  ${vw('margin-bottom', 40, 40, 48)}
  label {
    font-family: 'Roboto';
    line-height: normal;
    font-weight: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 14, 16, 16)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('margin-bottom', 8, 7, 7)}
  }
  input {
    outline: none;
    border: none;
    background-color: rgba(64, 96, 23, 0.1);
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 300;
    color: black;
    ${vw('font-size', 12, 14, 14)}
    ${vw('letter-spacing', 0.5, 0.7, 0.7)}
    ${vw('height', 33, 33, 33)}
    ${vw('padding-right', 11, 17, 16)}
    ${vw('padding-left', 11, 17, 16)}
  }
`;