export default [
  {
    name: 'A1',
    type: '1 Bedroom',
  },
  {
    name: 'A1a',
    type: '1 Bedroom',
  },
  {
    name: 'A1b',
    type: '1 Bedroom',
  },
  {
    name: 'A1c',
    type: '1 Bedroom',
  },
  {
    name: 'A2a',
    type: '1 Bedroom',
  },
  {
    name: 'A2b',
    type: '1 Bedroom',
  },
  {
    name: 'A3',
    type: '1 Bedroom',
  },
  {
    name: 'A4',
    type: '1 Bedroom',
  },
  {
    name: 'A5',
    type: '1 Bedroom',
  },
  {
    name: 'A5a',
    type: '1 Bedroom',
  },
  {
    name: 'A6',
    type: '1 Bedroom',
  },
  {
    name: 'B1',
    type: '2 Bedroom',
  },
  {
    name: 'B1c',
    type: '2 Bedroom',
  },
  {
    name: 'B2',
    type: '2 Bedroom',
  },
  {
    name: 'B3 Adaptable',
    type: '2 Bedroom',
  },
  {
    name: 'B3',
    type: '2 Bedroom',
  },
  {
    name: 'B3a',
    type: '2 Bedroom',
  },
  {
    name: 'B4',
    type: '2 Bedroom',
  },
  {
    name: 'B4a',
    type: '2 Bedroom',
  },
  {
    name: 'B4b',
    type: '2 Bedroom',
  },
  {
    name: 'B4c',
    type: '2 Bedroom',
  },
]