import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const NotFound = () => {
  return (
    <Root>
      <Logo />
      <Container>
        <h1>Sorry, page not found.</h1>
        <h2>We can't find what you're looking for.</h2>
        <StyledLink to='/'>Register Now</StyledLink>
      </Container>
    </Root>
  );
};

export default NotFound;

const Root = styled.div`
  height: 100vh;
  h1 {
    font-family: 'DINOT';
    font-weight: normal;
    line-height: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 24, 54, 54)}
    ${vw('letter-spacing', 0, 0)}
    ${vw('margin-bottom', 8, 16, 8)}
  }
  h2 {
    font-family: 'DINOT';
    font-weight: normal;
    line-height: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 16, 36, 36)}
    ${vw('letter-spacing', 0, 0)}
    ${vw('margin-bottom', 24, 40, 40)}
  }
  @media ${media.tablet} {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: normal;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ theme }) => theme.color.winterGreen};
  background-color: ${({ theme }) => theme.color.grass};
  transition: all 0.4s ease;
  ${vw('font-size', 12, 16, 16)}
  ${vw('letter-spacing', 1.5, 1.5, 1.5)}
  ${vw('width', 132, 190, 190)}
  ${vw('height', 34, 50, 50)}
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.lightGreen};
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70%;
  ${vw('padding-right', 22, 80, 224)}
  ${vw('padding-left', 23, 75, 193)}
  @media ${media.tablet} {
    height: 100%;
    width: ${vwTablet(512)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1008)};
  }
`;