import React from 'react';
import styled from 'styled-components';

import Logo from 'components/Logo';

import media from 'src/styles/media';
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';

const Hero = (props) => {
  const { $ref } = props;

  const handleClick = () => {
    window.scrollTo({
      top: $ref.current.getBoundingClientRect().top + window.scrollY,
      behavior: 'smooth',
    });
  }

  return (
    <Root>
      <Logo />
      <Container>
        <div>
          <img src={require('src/assets/images/sold-out-pattern.png')} alt='coming soon' />
          <h1>On the Park. In the City.</h1>
          {/* <button onClick={handleClick}>Register Now</button> */}
        </div>
      </Container>
    </Root>
  )
}

export default Hero;

const Root = styled.div`
  height: 100vh;
  @media ${media.tablet} {
    display: flex;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.winterGreen};
  height: 70%;
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    /* Coming Soon */
    /* align-items: flex-end; */
    img {
      /* Coming Soon */
      /* ${vw('width', 271, 440, 689)} */

      /* Sold Out */
      ${vw('width', 208, 345, 464)}
    }
    h1, button {
      position: relative;
      bottom: ${vwMobile(14)};
    }
    h1 {
      font-family: 'DINOT';
      font-weight: normal;
      color: ${({ theme }) => theme.color.white};
      white-space: nowrap;
      ${vw('font-size', 24, 40, 54)}
      ${vw('letter-spacing', 0, 0)}
      ${vw('margin-bottom', 24, 40)}
    }
    button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.color.grass};
      font-family: 'Roboto';
      color: ${({ theme }) => theme.color.winterGreen};
      text-transform: uppercase;
      ${vw('width', 132, 190, 190)}
      ${vw('height', 34, 50, 50)}
      ${vw('font-size', 14, 16, 16)}
      ${vw('letter-spacing', 1.5, 1.5, 1.5)}
      @media ${media.desktop} {
        &:hover {
          background-color: ${({ theme }) => theme.color.lightGreen};
          transition: all 0.4s ease;
        }
      }
    }
  }
  @media ${media.tablet} {
    width: ${vwTablet(512)};
    height: 100%;
    div {
      h1, button {
        position: relative;
        bottom: ${vwTablet(14)};
      }
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1008)};
    div {
      left: ${vwDesktop(120)};
      transform: translate(0, -50%);
      h1, button {
        position: relative;
        bottom: ${vwDesktop(24)};
        /* Coming Soon */
        /* left: ${vwDesktop(56)}; */
        
        /* Sold Out */
        left: ${vwDesktop(96)};
      }
    }
  }
`;