import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Checkbox = (props) => {
  const {
    name,
    values,
    checked,
    label,
    pattern,
    setField,
    required,
  } = props;
  return (
    <Root>
      <input 
        type='checkbox'
        name={name} 
        required={required}
        pattern={pattern}
        value={checked ? `${values[0]}` : `${values[1]}`}
        onChange={(e) => setField(e.target.checked)}
      />
      <label 
        htmlFor={name}
      >
        {label}
      </label>
    </Root>
  )
}

export default Checkbox;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  ${vw('width', 295, 465, 928)}
  ${vw('margin-bottom', 40, 40, 56)}
  input {
    position: relative;
    appearance: none;
    cursor: pointer;
    ${vw('width', 33, 45, 45)}
    ${vw('height', 33, 45, 45)}
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(64, 96, 23, 0.1);
    }
    &:checked {
      &:before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        background-color: ${({ theme }) => theme.color.grass};
      }
    }
  }
  label {
    font-family: 'Roboto';
    line-height: normal;
    font-weight: normal;
    ${vw('font-size', 14, 16, 16)}
    ${vw('line-height', 18, 24, 24)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('width', 250, 392, 854)}
    a {
      color: ${({ theme }) => theme.color.everGreen};
    }
  }
`;