import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import Insight from 'src/plugins/Insight';
import { loadReCaptcha } from 'react-recaptcha-v3';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';
import { recaptcha } from 'src/config/app.conf';

import Footer from 'src/components/Footer';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    Insight();
    loadReCaptcha(recaptcha, this.afterRecaptchaLoad);
  }

  componentDidUpdate() {
    // Hiding the recaptcha badge on all pages but register
    if (this.props.location.pathname.includes('/')) {
      if (document.getElementsByClassName('grecaptcha-badge')[0]) {
        document.getElementsByClassName(
          'grecaptcha-badge'
        )[0].style.opacity = 1;
      }
    } else {
      if (document.getElementsByClassName('grecaptcha-badge')[0]) {
        document.getElementsByClassName(
          'grecaptcha-badge'
        )[0].style.opacity = 0;
      }
    }
  }

  afterRecaptchaLoad = () => {
    if (!this.props.location.pathname.includes('/')) {
      if (document.getElementsByClassName('grecaptcha-badge')[0]) {
        document.getElementsByClassName(
          'grecaptcha-badge'
        )[0].style.opacity = 0;
      }
    }
  };

  generateRoutes() {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <Root>
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Switch location={location}>{this.generateRoutes()}</Switch>
              </CSSTransition>
            </TransitionGroup>
            <Footer />
          </Root>
        )}
      />
    );
  }
}

const Root = styled.div`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

export default process.env.NODE_ENV === 'development'
  ? hot(module)(withRouter(App))
  : withRouter(App);
