import React from 'react';
import styled from 'styled-components';
import { useScrollDown } from 'bam-storybook';

import vw from 'src/styles/utils';

const Header = () => {
  const hasScrolledDown = useScrollDown(300);

  return (
    <Root 
      src={require('src/assets/images/pattern-header.jpg')}
      hasScrolledDown={hasScrolledDown}
    >
      <img src={require('src/assets/images/logo-horizontal.svg')} alt='holland two' />
    </Root>
  )
}

export default Header;

const Root = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  transform:  ${props => props.hasScrolledDown ? 'translateY(0%)' : 'translateY(-100%)'};
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  z-index: 1;
  ${vw('height', 72, 72)}
  img {
    height: auto;
    ${vw('width', 191, 191)}
  }
`;