import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from 'src/styles/utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .form-label-01 {
    font-family: 'Roboto';
    font-weight: 600;
    color: ${theme.color.winterGreen};
    text-transform: uppercase;
    ${vw('font-size', 20, 28)}
    ${vw('letter-spacing', 0.5, 1)}
    ${vw('margin-bottom', 40, 56)}
  }
  .form-label-02 {
    font-family: 'Roboto';
    font-weight: normal;
    color: ${theme.color.winterGreen};
    text-transform: uppercase;
    ${vw('font-size', 18, 24)}
    ${vw('letter-spacing', 0.5, 0.5)}
    ${vw('margin-bottom', 40, 40)}
  }
`;
