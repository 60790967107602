import React from 'react';
import styled from 'styled-components';

const facebook_icon = (props) => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#7A9A01"
                d="M0 0H32V32H0z"
                transform="translate(-829.000000, -1727.000000) translate(0.000000, 1630.000000) translate(829.000000, 97.000000)"
                id='bg'
              />
              <path
                fill="#07272D"
                fillRule="nonzero"
                d="M20.346 7.13v2.856h-1.698c-1.33 0-1.58.638-1.58 1.557v2.045h3.17l-.422 3.202H17.07V25h-3.31v-8.21H11v-3.202h2.758V11.23c0-2.737 1.677-4.23 4.122-4.23 1.168 0 2.174.087 2.466.13z"
                transform="translate(-829.000000, -1727.000000) translate(0.000000, 1630.000000) translate(829.000000, 97.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </Root>
  )
}

export default facebook_icon;

const Root = styled.svg``;
