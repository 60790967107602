import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ReCaptcha } from 'react-recaptcha-v3';

import Input from '../elements/Input';
import Select from '../elements/Select';
import Checkbox from '../elements/Checkbox';
import PageWrapper from 'components/PageWrapper';
import Insight from 'src/plugins/Insight';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import { recaptcha, rs } from 'src/config/app.conf';

const Form = (props) => {
  const { $ref } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [postal, setPostal] = useState('');
  const [type, setType] = useState('');
  const [heard, setHeard] = useState('');
  const [realtor, setRealtor] = useState('');
  const [consent, setConsent ] = useState(false);
  const [guid, setGuid] = useState('');
  const [honeyPot, setHoneyPot] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const $form = useRef();
  const $recaptcha = useRef();

  useEffect(() => {
    let LassoCRM = {};
    LassoCRM.tracker = new LassoAnalytics('LAS-132994-32');
    LassoCRM.tracker.setTrackingDomain("//app.lassocrm.com");
    
    try {
      LassoCRM.tracker.init();  // initializes the tracker
      LassoCRM.tracker.track({hostname: 'townline.ca'});
      LassoCRM.tracker.patchRegistrationForms();
    } catch(error) {}

    setGuid(LassoCRM.tracker.guid);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      postal_code : postal,
      type: type,
      heard: heard,
      realtor: realtor,
      consent: consent,
    };

    await updateToken();

    if (honeyPot !== '' || !recaptchaResponse.success) {
      e.preventDefault();
      return alert(
        `Our systems seem to think you're a bot!  Please email hollandtwo@townline.ca directly to receive more information about The Holland Two.`
      );
    }

    window.history.pushState('Thank You', 'Thank You', '/thank-you');
    Insight(data);
    $form.current.submit();
    setIsSubmitted(true);
    setTimeout(() => {
      window.scrollTo({
        top: $ref.current.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      });
    }, 400)
  }

  const verifyCallback = async (token) => {
    const PROXY = 'https://bam-cors-anywhere.herokuapp.com/';
    const verifyURL = `${PROXY}https://www.google.com/recaptcha/api/siteverify?secret=${rs}&response=${token}`;

    const response = await fetch(verifyURL, {
      method: 'POST',
      accept: 'application/json',
    });
    const responseJson = await response.json();

    setRecaptchaResponse(responseJson);
  };

  const updateToken = async () => {
    // you will get a new token in verifyCallback
    await $recaptcha.current.execute();
  };

  return (
    <Root ref={$ref}>
      <PageWrapper>
        <iframe name="iframeSubmission" title="Frame Submission" />
        <FormWrapper
          isSubmitted={isSubmitted}
        >
          <h2>1, 2 + 3 Bedroom Parkside Residences + Townhomes</h2>
          <form 
            method="post"
            action="https://app.lassocrm.com/registrant_signup/"
            target="iframeSubmission"
            onSubmit={(e) => handleSubmit(e)}
            isSubmitted={isSubmitted}
            ref={$form}
          >
            <ReCaptcha
              sitekey={recaptcha}
              action="registration"
              verifyCallback={verifyCallback}
              ref={$recaptcha}
            />
            <input type="hidden" name="domainAccountId" value="LAS-132994-32" />
            <input type="hidden" name="guid" value={guid} />
            <input type="hidden" name="LassoUID" value="iSR)u*G)r9"/>
            <input type="hidden" name="ClientID" value="19"/>
            <input type="hidden" name="ProjectID" value="16942"/>
            <input type="hidden" name="ThankYouEmailTemplateId" value="735984"/>
            <input
              onChange={(e) => setHoneyPot(e.target.value)}
              id="get_contact"
              name="get_contact"
              type="text"
              autoComplete="off"
              tabIndex="-1"
            />
            <Row>
              <Input
                name='FirstName'
                label='First name*'
                setField={setFirstName}
                required
              />

              <Input
                name='LastName'
                label='Last name*'
                setField={setLastName}
                required
              />
            </Row>

            <Row>
              <Input
                name='Emails[Primary]'
                label='Email address*'
                setField={setEmail}
                required
              />

              <Input
                name='Phones[Home]'
                label='Phone number*'
                setField={setPhone}
                required
              />
            </Row>

            <Row>
              <Input
                name='PostalCode'
                label='Postal code*'
                setField={setPostal}
                required
              />

              <Select
                name='Questions[114671]'
                label='What type of home are you looking for?'
                options={[
                  {
                    id: '308799',
                    name: '1 Bed',
                  },
                  {
                    id: '308800',
                    name: '2 Bed',
                  },
                  {
                    id: '308801',
                    name: '3 Bed',
                  }
                ]}
                setField={setType}
              />
            </Row>

            <Row>
              <Select
                name='Questions[114134]'
                label='How did you hear about Holland Two?'
                options={[
                  {
                    id: '308265',
                    name: 'Buzz Buzz Homes',
                  },
                  {
                    id: '308266',
                    name: 'Direct Mail',
                  },
                  {
                    id: '308267',
                    name: 'Email',
                  },
                  {
                    id: '308268',
                    name: 'Friends/Family Referral',
                  },
                  {
                    id: '308269',
                    name: 'Online Ads',
                  },
                  {
                    id: '308270',
                    name: 'Print Ads',
                  },
                  {
                    id: '308271',
                    name: 'Radio',
                  },
                  {
                    id: '308272',
                    name: 'Realtor',
                  },
                  {
                    id: '308273',
                    name: 'Signage/Drive By',
                  },
                  {
                    id: '308274',
                    name: 'Social Media',
                  },
                  {
                    id: '308275',
                    name: 'TV',
                  },
                  {
                    id: '308276',
                    name: 'Web Search',
                  },
                  {
                    id: '308277',
                    name: 'Website',
                  },
                  {
                    id: '308278',
                    name: 'WeChat',
                  },
                  {
                    id: '308279',
                    name: 'Word of Mouth',
                  },
                ]}
                setField={setHeard}
              />

              <Select
                name='Questions[114672]'
                label='Are you a Realtor?'
                options={[
                  {
                    id: '308802',
                    name: 'Yes',
                  },
                  {
                    id: '308803',
                    name: 'No',
                  }
                ]}
                setField={setRealtor}
              />
            </Row>

            <Checkbox
              name='Questions[114673]'
              values={[
                '308804', // Yes
                '', // No
              ]}
              checked={consent}
              label={<>Yes, I would like to confirm my interest as a Townline VIP and I'm interested in receiving priority email updates, invitations and announcements on Holland Two as well as future Townline project announcements, newsletters, etc. I understand that I can unsubscribe at any time. <a href='https://www.townline.ca/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></>}
              setField={setConsent}
              required
            />

            <button type="submit" value="Submit">Submit</button>
          </form>
        </FormWrapper>
        <ThankYou isSubmitted={isSubmitted}>
          <h1>Thank you for registering.</h1>
          <h2>We look forward to sharing further information with you soon.</h2>
        </ThankYou>
      </PageWrapper>
    </Root>
  )
}

export default Form;

const Root = styled.div`
  position: relative;
  iframe {
    display: none;
  }
  #get_contact {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: -50px;
    left: -50px;
    pointer-events: none;
  }
  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-weight: normal;
    text-transform: uppercase;
    line-height: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    background-color: ${({ theme }) => theme.color.grass};
    transition: all 0.4s ease;
    ${vw('font-size', 12, 16, 16)}
    ${vw('letter-spacing', 1.5, 1.5, 1.5)}
    ${vw('width', 93, 190, 190)}
    ${vw('height', 34, 50, 50)}
    @media ${media.desktop} {
      &:hover {
        background-color: ${({ theme }) => theme.color.lightGreen};
      }
    }
  }
`;

const FormWrapper = styled.div`
  display: ${props => props.isSubmitted ? 'none' : 'block'};
  ${vw('padding-top', 144, 72, 80)}
  ${vw('padding-right', 13, 31, 81)}
  ${vw('padding-bottom', 80, 72, 80)}
  ${vw('padding-left', 12, 272, 432)}
  form {
    ${vw('width', 295, 465, 927)}
  }
  h2 {
    font-family: 'DINOT';
    font-weight: normal;
    letter-spacing: 0;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 24, 36, 36)}
    ${vw('margin-bottom', 40, 48, 56)}
  }
`;

const Row = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const ThankYou = styled.div`
  display: ${props => !props.isSubmitted ? 'none' : 'block'};
  ${vw('padding-top', 192, 120, 233)}
  ${vw('padding-right', 33, 36, 0)}
  ${vw('padding-bottom', 120, 295, 414)}
  ${vw('padding-left', 12, 256, 432)}
  h1 {
    font-family: 'DINOT';
    font-weight: normal;
    line-height: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 24, 54, 54)}
    ${vw('letter-spacing', 0, 0)}
    ${vw('margin-bottom', 8, 16, 8)}
  }
  h2 {
    font-family: 'DINOT';
    font-weight: normal;
    line-height: normal;
    color: ${({ theme }) => theme.color.winterGreen};
    ${vw('font-size', 16, 36, 36)}
    ${vw('letter-spacing', 0, 0)}
  }
`;