import React from 'react';
import styled from 'styled-components';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const PageWrapper = (props) => {
  return (
    <Root>
      <Container>
        <img src={require('src/assets/images/register-now.png')} alt='' />
      </Container>
      {props.children}
    </Root>
  )
}

export default PageWrapper;

const Root = styled.div``;

const Container = styled.div`
  position: absolute;
  height: 80%;
  ${vw('top', 48, 70, 70)}
  ${vw('left', 12, 56, 119)}
  img {
    position: absolute;
    transform: rotate(90deg);
    top: ${vwMobile(-98)};
    left: ${vwMobile(84)};
    ${vw('width', 80, 144, 194)}
  }
  @media ${media.tablet} {
    img {
      transform: none;
      position: sticky;
      top: ${vwTablet(72)};
    }
  }
  @media ${media.desktop} {
    img {
      top: ${vwDesktop(80)};
    }
  }
`;