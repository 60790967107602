import React from 'react';
import styled from 'styled-components';

const twitter_icon = (props) => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#7A9A01"
                d="M0 0H32V32H0z"
                transform="translate(-873.000000, -1727.000000) translate(0.000000, 1630.000000) translate(873.000000, 97.000000)"
                id='bg'
              />
              <path
                fill="#07272D"
                fillRule="nonzero"
                d="M26 9.929c-.558.812-1.256 1.535-2.056 2.12.013.177.013.355.013.532 0 5.419-4.125 11.663-11.663 11.663-2.322 0-4.48-.673-6.294-1.84.33.038.647.05.99.05 1.916 0 3.68-.647 5.089-1.751-1.802-.038-3.313-1.218-3.833-2.843.254.038.508.064.774.064.368 0 .736-.05 1.079-.14-1.878-.38-3.287-2.03-3.287-4.023v-.05c.546.304 1.18.495 1.853.52-1.104-.736-1.827-1.992-1.827-3.414 0-.761.203-1.46.558-2.068 2.018 2.487 5.05 4.111 8.452 4.29-.064-.305-.102-.623-.102-.94 0-2.259 1.828-4.099 4.1-4.099 1.18 0 2.245.495 2.994 1.294.926-.177 1.815-.52 2.602-.99-.305.952-.952 1.752-1.802 2.26.824-.09 1.624-.318 2.36-.635z"
                transform="translate(-873.000000, -1727.000000) translate(0.000000, 1630.000000) translate(873.000000, 97.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </Root>
  )
}

export default twitter_icon;

const Root = styled.svg``;
