module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'zh'],
  prerender: true,
  dbProject: 'holland-2-teaser',
  analytics: {
    gtag: '',
    gtmId: 'GTM-WCG5PDJ',
    optimizeId: '',
    hotjarId: '',
  },
  recaptcha: '6Ld9FBUdAAAAAG8m30Krl7HDhYRXw87T8e-lR3_P',
  rs: '6Ld9FBUdAAAAABLYHE4nde5gk5wSoujgB2MihRL7',
  form: {
    spreadsheet_id: '1Le-7ynyGaSPW53XmtBKupdpjWjIxbXwIYTne92VJU0A',
    sheet_id: '0',
    client_email: 'home-request-form@holland-2.iam.gserviceaccount.com',
    private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCd151WhJeWAiy7\nRWcmWa4uK75BNZgJbbafr2+6bRESjuirOGN4AQXzEA6PQD48xuXK+GEm8ir9oyOM\nbYVWaFUGeJe8XinUOokPID5KFyJxFUrNRYpjZwQsvLgU/rLB9LbH9CFk4fAAiP3M\nYJgB54Fjnhwx7cenjqWDSZp7XBodMNxHNu2q0HgN/g3wnBJuw/wj27zxCB+sTJLY\nrhUYrMfxIsYOeAJyQzhyxKMgcs2+40vMh/BYwz3K1t4HJ+DjILFH5FhffXCxYDpS\nzG9ihBoUOi+YEJIYiP2PFV/7xULkeREe6z7sPTkOVwXsVgcW1hB7WMWdnNJJ+Eij\nGm+57t+zAgMBAAECggEAFeF26TB2RxBEHS3Qz8sszy+3Lxj724q+/0H1rhVCgZ0X\n+exWNaOGrJw0OH2CYzB7WHi4FwsJFKIR4nyoOEM8TipXdoBjMg1vRI6GtASi/KQj\n+3LBbWgxdvYuWpK4OxTuZaedHXwIpjpXXIaja9go4qNfazh4Le0jWqDQbntlD1in\nznMqgrzPm93md69dQJTXpCXTGTqqao05ZOUXgZ9KtxPDPAxU7cg5xh0b6ScB1cik\nXUHabpmYtCMksUBxyK4/83qCdL8RLruIpBuEh2pbEm/T/rMrOabpEzGh/B9N8rep\nYJepp4zu1YxC3K6b3NWfsoZC3q4aQ60lu28iYYfYUQKBgQDUsEEKQ/XfYHP+6YLg\nqxD71vFRofS4Fl7uI44Hz9wrVd12aBavkggUSo2r9xAFXYXyvG5s+9NrHKOVEJ/g\n+uGWRZMKj64eTG+WOZRdgOt+qiTg+XHMhtOu7YxKgDQJHqjz6Kje09GGjbX5qQ/Z\nTDd2NTBI7zkd/mx+Beaw9c9xOwKBgQC9/CZMvCs0bbN4rg9HAy6rZkNXztfKEBAx\nCDzPho8sFIIyGKCVrviRgfARcdWqhGevZ3JBYoPrbbRYtBxxJv+ohzjDMgZT8yLC\nfB7VEwYtx0WQfFKUZy7utYWrV4Thr8nbGTTQjI4ezm0+nnldD+yrdWoTNiAMuZIe\nvPZ9oJVj6QKBgDNqZF657R9R7tip1JTBZfqrjGzijQlPhyVkfPpQenYTbNUp9on8\n8G0PVM5EjJ/vaukyOjOocQSMyNLJUQ0POSedrB06fKogaj1TsBUmjEdzlDS6uJLr\n6RJ5pJeCBJJL0iYckW5EoHs5mMtA3m72aNXvp7uJ8THaBpo0jP8sfThvAoGAPzsQ\ntDFcX9KzDNNBmmzgt4MNnsfk4gd+nBMVyiwKsq0QuAOiTq8xY5xPfj7Y0ivHJoXT\nbReQuGaGw48OeEp2hGyc47peiMo/I2OUD26Je+r6qB/4jBPbdu9LUb+LJmIPWLCG\n93IQSwiBK3orAmIubtJhP4ugFxwcGcbicuVjUyECgYBkou4p2x7vOcZBNpHRab7t\nMt6YeYcD0fuHv54w3DDWn59AWgDjD4whBq/+3EZt0YtsYD4fSjs1s8O59LVEEOOn\n1EgcX4pnVdfJc2HjeI1BEsbnOljmv+Ppa6qajXdJCVY5QM6pGAX79id4nhWsnRpv\nzGxIuvjdZqmxvWGvqrS1Iw==\n-----END PRIVATE KEY-----\n',
  },
  seo: {
    title: 'Holland Two',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
